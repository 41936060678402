import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { ReactiveBase, ReactiveList } from "@appbaseio/reactivesearch";
import { isMobileOnly, isMobile, isIPad13 } from "react-device-detect";
import Warehouse from "../../components/Warehouse";
import MemoOut from "../../components/MemoOut";
import StyleNumber from "../../components/StyleNumber";
import SerialNumber from "../../components/SerialNumber";
import ProductLoader from "../../components/ProductLoader";
import RetailPriceRange from "../../components/RetailPriceRange";
import MountedNumberStock from "../../components/MountedNumberStock";
import IsSold from "../../components/IsSold";
import LooseOnly from "../../components/LooseOnly";
import IsRtv from "../../components/IsRtv";
import IsVirtual from "../../components/IsVirtual";
import GemstoneShape from "./GemstoneShape";
import CaratWeight from "./CaratWeight";
import CountryofOrigin from "./CountryofOrigin";
import SearchCriteria from "../../components/SearchCriteria";
import Keyword from "../../components/Keyword";
import GemEnhancement from "./GemEnhancement";
import moment from "moment";
import WholesalePriceRange from "../../components/WholesalePriceRange";
import GemstoneType from "./GemstoneType";
import ReactImageFallback from "react-image-fallback";
import currencyFormatter from "currency-formatter";
import ShowCode from "../../components/ShowCode";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import cx from "classnames";
import ShowMore from "react-show-more";
import StockModal from "../../components/GemstoneStockModal";
import SoldModal from "../../components/SoldModal";
import ImageModal from "../../components/ImageModal";
import MobileProductDetails from "./MobileProductDetails";
import MobileProductDetailModal from "./MobileProductDetailModal";
import { BaseURL } from "../../utils/constants";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import {
  setBasketFormInput,
  toggleIframeModal,
  addToCart,
  toggleLoader,
  setToken,
  setEmployeeData,
  setFeedsHoldData,
  setJewelryHoldData
} from "../../actions/index";
import Appbase from "appbase-js";
import axios from "axios";
import {
  AppbaseEmployeeApp,
  basketBaseUrl,
  ApiKey,
  ApiBaseUrl,
  basketApikey,
  appbaseBasketUrl,
  AppbaseHoldNotesApp,
  AppbaseCoverApp,
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseCustomerApp,
  AppbaseAttributesApp,
  holdNoteApi,
  holdNoteApiKey
} from "../../utils/constants";
import GetAuthToken from "../../components/Api/Authenticate";
import async from "async";
import { gemstoneDataExport } from "./../../components/RemoveFieldsExport";
import SortDropdown from "./SortDropdown";
import PreviewEmailModal from "../../components/Basket/PDF/PreviewEmailModal";
import PDFModal from "../../components/Basket/PDF/PDFModal";
import ChooseLayoutModal from "../../components/Basket/ChooseLayoutModal";
import PDF from "../../assets/icons/pdf-black-16.png";
import HandleWholesale from "../../components/HandleWholesale";
import ShowFileModal from "../../components/ShowFileModal";
import RfidSearch from "../../components/RfidSearch";
import InShowCase from "./InShowCase";
import IsConsignmentItem from "../../components/IsConsignmentItem";
import HoldNotesArchievedModal from "../../components/HoldNotesArchievedModal";
import HoldNoteCreateModal from "../../components/HoldNoteCreateModal";
import {
  fetchApiData,
  getHoldNotesData
} from "../../components/holdNotesApiData";
import GemstoneAppResults from "./GemstoneAppResults";
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
  return {
    // cartItems: state.cartActions,
    basketInputObj: state.basketInputChange,
    loaderActions: state.loaderActions,
    tokenState: state.tokenState,
    apiData: state.apiData,
    groups: state.SetGroups.groups
  };
};
class Main extends Component {
  csvLink = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      selected: "CaratWeight",
      title: "Sort By",
      sort: "asc",
      active: "active1",
      checked: false,
      showCustomLoader: false,
      access: "basic",
      serialSearchSignal: "NOT_ACTIVE",
      soldSignal: false,
      serialSearchSelectedSignal: false,
      mountedSearchSignal: "NOT_ACTIVE",
      showStockModal: false,
      showImageModal: false,
      soldModal: false,
      result: [],
      keywordSearchSignal: "NOT_ACTIVE",
      keywordSearchSelectedSignal: false,
      rfidSearchSignal: "NOT_ACTIVE",
      rfidSearchSelectedSignal: false,
      showFilteredData: false,
      mobileClassToggle: false,
      showMobileProductModal: false,
      csvData: [],
      nextQuery: {},
      fromLoadMore: false,
      sizeLimit: 15,
      countValue: 1,
      loadMoreClicked: false,
      showSelectedResultsOnEnter: false,
      currentRes: [],
      showPreviewModal: false,
      showPDFModal: false,
      showChooseLayout: false,
      layoutType: "",
      coverType: "NoCover",
      goto: "",
      includeGIA: "No",
      showFileModal: false,
      // fileIdNames:
      //   "noimage.png:3FE31D95-96F4-48D7-9B34-E89E600DE610|noimagebig.png:402E021D-4706-4999-AE9E-C4498162AC2F",
      fileIdNames: "noimage.png:3FE31D95-96F4-48D7-9B34-E89E600DE610",
      // |noimagebig.png:402E021D-4706-4999-AE9E-C4498162AC2F",
      // user: "",
      fileData: [],
      coverSelected: "",
      coverCategories: [],
      covers: [],
      // showHoldNotesModal: false,
      // showHoldNotesEditModal: false,
      // holdNotesData: "",
      // showHoldNoteCreateModal: false,
      // customers: "",
      feedRestrictionsData: this.props.apiData.feedsData,
      jewelryRestrictionData: this.props.apiData.jewelryHoldData,
      employeeData: this.props.apiData.empData,
      // holdNoteItem: "",
      includeKTDShapes: "Yes",
      postMessageSerial: ""
    };
    this.onSearch = this.onSearch.bind(this);
    this.onCheckSelect = this.onCheckSelect.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getProduct = this.getProduct.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.serialSearchChanged = this.serialSearchChanged.bind(this);
    this.serialSearchSelected = this.serialSearchSelected.bind(this);
    this.mountedSearchChanged = this.mountedSearchChanged.bind(this);
    this.showStockPopUpModal = this.showStockPopUpModal.bind(this);
    this.hideStockPopUpModal = this.hideStockPopUpModal.bind(this);
    this.showSoldModal = this.showSoldModal.bind(this);
    this.hideSoldModal = this.hideSoldModal.bind(this);
    this.showZoomImage = this.showZoomImage.bind(this);
    this.hideImageModal = this.hideImageModal.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleAllOptions = this.handleAllOptions.bind(this);
    this.showMobileProductModal = this.showMobileProductModal.bind(this);
    this.hideMobileProductModal = this.hideMobileProductModal.bind(this);
    this.downloadResults = this.downloadResults.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMail = this.handleMail.bind(this);
    this.handleTextMsg = this.handleTextMsg.bind(this);
    this.handleWhatsApp = this.handleWhatsApp.bind(this);
    this.handleSoldSignal = this.handleSoldSignal.bind(this);
    this.showSelectedResults = this.showSelectedResults.bind(this);
    this.keywordSearchChanged = this.keywordSearchChanged.bind(this);
    this.keywordSearchSelected = this.keywordSearchSelected.bind(this);
    this.rfidSearchChanged = this.rfidSearchChanged.bind(this);
    this.rfidSearchSelected = this.rfidSearchSelected.bind(this);
    this.showPreviewModal = this.showPreviewModal.bind(this);
    this.hidePreviewModal = this.hidePreviewModal.bind(this);
    this.showPDFModal = this.showPDFModal.bind(this);
    this.hidePDFModal = this.hidePDFModal.bind(this);
    this.showChooseLayout = this.showChooseLayout.bind(this);
    this.hideChooseLayout = this.hideChooseLayout.bind(this);
    this.handleSetLayout = this.handleSetLayout.bind(this);
    this.handleSetCover = this.handleSetCover.bind(this);
    this.handleCurrentRes = this.handleCurrentRes.bind(this);
    // this.getContactEmail = this.getContactEmail.bind(this);
    // this.getUserEmail = this.getUserEmail.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleIncludeGIA = this.handleIncludeGIA.bind(this);
    this.handleAddToBasket = this.handleAddToBasket.bind(this);
    this.handleShowFileIdNames = this.handleShowFileIdNames.bind(this);
    this.hideFileModal = this.hideFileModal.bind(this);

    this.fetchCovers = this.fetchCovers.bind(this);
    // this.handleSelectedCover = this.handleSelectedCover.bind(this);
    // this.handleHoldNotesModals = this.handleHoldNotesModals.bind(this);
    // this.handleHoldNoteArchive = this.handleHoldNoteArchive.bind(this);
    // this.handleItemHoldNoteUpdate = this.handleItemHoldNoteUpdate.bind(this);
    // this.handleHoldNotesCreateModal =
    //   this.handleHoldNotesCreateModal.bind(this);
    // this.getHoldNotesData = this.getHoldNotesData.bind(this);
    // this.getContactsData = this.getContactsData.bind(this);
    this.handleIncludeKTDShapes = this.handleIncludeKTDShapes.bind(this)
  }

  handleIncludeKTDShapes (value) {
    this.setState({includeKTDShapes: value})
   };

  async componentDidMount() {
    // if (this.props.tokenState.token === "") {
    //   var token = await GetAuthToken();
    //   if (token) {
    //     this.props.setToken(token.access_token);
    //   }
    // }

    const handleMessageFromParent = (event) => {
      // if (event.origin !== 'https://acu-react-front-end.pages.dev') {
      //   return;
      // }

      const message = event.data;
      if (message.type === 'processData') {
        console.log('Data received from parent:============>', message.data);
        this.setState((prev)=>{
          return {
            ...prev,
            postMessageSerial: message.data.serial
          }
        })
        this.props.history.push(`/${message.data.path}/serial?Serial=${message.data.serial}`);
      }
    };
    window.addEventListener('message', handleMessageFromParent);

    let uRoleEl = document.getElementById("ctl00_phF_form_edRolename");
    if (!uRoleEl) {
      // return;
    }
    setTimeout(() => {
      if (Object.keys(this.props.groups).length !== 0) {
        this.setState({
          access: this.props.basketInputObj.access
        });
      } else if (uRoleEl) {
        let uRole = document.getElementById("ctl00_phF_form_edRolename").value;
        uRole = toLower(uRole);
        if (
          uRole.includes("admin") ||
          uRole.includes("executive") ||
          uRole.includes("production") ||
          uRole.includes("elastic")
        ) {
          this.setState({
            access: "advanced"
          });
          this.props.setBasketFormInput({
            access: "advanced"
          });
        }
        // if (uRole.includes("ADCRM".toLowerCase())) {
        // if (!uRole.includes("ADCRM".toLowerCase())) {
        //   this.props.setBasketFormInput({
        //     holdNotesAccess: true
        //   });
        // }
      }
    }, 1500);
    // await this.fetchCovers();
    let empData = await fetchApiData(AppbaseEmployeeApp);
    const loggedUser = JSON.parse(sessionStorage.getItem('reduxState'))
    const loggedUserEmail = loggedUser?.basketInputChange?.user?.Email
    empData.forEach((item)=>{
      if(item?._source?.Email === loggedUserEmail && item?._source?.bulkHoldNotes == "1"){
        this.props.setBasketFormInput({
          holdNotesAccess: true
        });
      }
    })
  }
  // handleItemHoldNoteUpdate(data) {
  //   this.setState((prevState) => ({
  //     results: prevState.results.map((result) => {
  //       if (result.SerialNumber === data.SerialNumber) {
  //         return data;
  //       } else {
  //         return result;
  //       }
  //     }),
  //   }));
  //   this.getProduct(this.state.results);
  // }

  // async handleHoldNoteArchive(res) {
  //   let result = window.confirm("Confirm Hold Note Archieve ?");
  //   if (result) {
  //     this.props.toggleLoader({
  //       isLoading: true,
  //     });
  //     let payload = {
  //       data: {
  //         SerialNbr: res.SerialNumber,
  //         InventoryID: res.InventoryID,
  //       },
  //     };
  //     var resp = await axios
  //       .post(holdNoteApi + "archiveholdnote", payload, {
  //         headers: {
  //           "x-api-key": holdNoteApiKey,
  //         },
  //       })
  //       .catch((error) => {
  //         console.log("error :", error);
  //         // toast.error("Error while saving !", {
  //         //   position: toast.POSITION.TOP_RIGHT,
  //         //   autoClose: 2000,
  //         //   pauseOnHover: false,
  //         // });
  //         // this.props.toggleLoader({
  //         //   isLoading: false,
  //         // });
  //       });
  //     console.log("resp: ", resp);

  //     if (
  //       resp &&
  //       resp.data &&
  //       resp.data.statusCode &&
  //       resp.data.statusCode === 200
  //     ) {
  //       this.props.toggleLoader({
  //         isLoading: false,
  //       });
  //       this.setState((prevState) => ({
  //         results: prevState.results.map((result) => {
  //           if (result.SerialNumber === res.SerialNumber) {
  //             return {
  //               ...result,
  //               // HoldBy: null,
  //               HoldCustomerId: null,
  //               HoldCustomerName: null,
  //               HoldDate: null,
  //               HoldEnteredByID: null,
  //               HoldEnteredByName: null,
  //               HoldEnteredForID: null,
  //               HoldEnteredForName: null,
  //               HoldNoteID: null,
  //               HoldNoteNbr: null,
  //               HoldText: null,
  //               ReleaseDate: null,
  //               IsHoldSold: null,
  //             };
  //           } else {
  //             return result;
  //           }
  //         }),
  //       }));
  //     } else {
  //       this.props.toggleLoader({
  //         isLoading: false,
  //       });
  //     }
  //   }
  // }

  // async handleHoldNotesModals(id, value, type, res) {
  //   this.setState({ holdNoteItem: res });
  //   let response;
  //   if (id && value) {
  //     // await this.getHoldNotesData(id);
  //     response = await getHoldNotesData(id, AppbaseHoldNotesApp);
  //     this.setState({ holdNotesData: response });
  //   }
  //   if (
  //     value &&
  //     type === "archivedHoldNotes" &&
  //     (!response ||
  //       response.length === 0 ||
  //       response.filter(
  //         (data) => data._source.IsArchived && data._source.IsArchived === "1"
  //       ).length === 0)
  //   ) {
  //     window.alert("There are no Hold Notes.");
  //     return;
  //   }
  //   this.setState({
  //     ...(type === "archivedHoldNotes" ? { showHoldNotesModal: value } : {}),
  //     ...(type === "archivedHoldNotes" && !value ? { holdNotesData: "" } : {}),
  //     ...(type === "createHoldNotes" ? { showHoldNoteCreateModal: value } : {}),
  //     ...(!value ? { showHoldNotesEditModal: false } : {}),
  //   });

  //   // if (!value) {
  //   //   this.setState({ holdNotesData: "" });
  //   // }
  // }

  handleSelectedCover(value) {
    // console.log(value);
    if (value) {
      this.setState(
        prevState => ({
          coverSelected: value
          // prevState.covers.filter(
          //   (cover) => cover.id === value
          // )[0],
        })
        // () => console.log("coverSelecetd: ", this.state.coverSelected)
      );
    } else {
      this.setState({ coverSelected: "" });
    }
  }

  async fetchCovers() {
    const appbaseRef = Appbase({
      app: AppbaseCoverApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials
    });

    let response = await appbaseRef
      .search({
        body: {
          query: {
            match_all: {}
          }
        }
      })
      .then(function(res) {
        // console.log("Templates results: ", res);
        return res;
      })
      .catch(function(err) {
        console.log("Covers error: ", err);
        return err;
      });

    if (response && response.hits.hits.length !== 0) {
      console.log("Covers found successfully");
      this.setState(prevState => ({
        ...prevState,
        covers: response.hits.hits.map(data => {
          return { id: data._id, ...data._source };
        }),
        coverCategories: [
          ...new Set(
            response.hits.hits.map(cover => {
              return cover._source.Category;
            })
          )
        ]
      }));
    } else {
      console.log("covers not found");
    }
  }

  handleShowFileIdNames(res) {
    let { dwebbDefault } = this.props.basketInputObj;
    if (dwebbDefault) {
      window
        .open(
          `${BaseURL}/Main?ScreenId=KW801120&InventoryID=${res.StyleNumber}&LotSerialNbr=${res.SerialNumber}`,
          "_blank"
        )
        .focus();
    } else {
      let fileIdNames = res.FileIdNames;
      let fileData = fileIdNames.split("|");
      // console.log("fileData: ", fileData);
      if (fileData.length === 1) {
        let fileID = fileData[0].slice(fileData[0].indexOf(":") + 1);
        // console.log("fileID: ", fileID);
        window
          .open(BaseURL + "/Frames/GetFile.ashx?fileID=" + fileID, "_blank")
          .focus();
      } else {
        this.setState({ fileData, showFileModal: true });
      }
    }
  }

  hideFileModal() {
    this.setState({ showFileModal: false });
  }

  handleAddToBasket(results) {
    results.map((res, i) => {
      this.props.addToCart({
        product: res,
        productType: "G",
        assetOrmemo: this.props.basketInputObj.assetOrmemo
      });
    });
  }
  handleIncludeGIA(value) {
    this.setState({ includeGIA: value });
  }

  // async getUserEmail() {
  //   let { basketInputObj, tokenState } = this.props;
  //   let userEmail;
  //   if (window.parent.document.getElementById("btnUserMenu")) {
  //     // userEmail = window.parent.document
  //     //   .getElementById("btnUserMenu")
  //     //   .querySelector(".user-name").textContent;
  //     userEmail = window.parent.document
  //       .getElementById("userMenu")
  //       .querySelector(
  //         "li.menu-form > div.inline > span:nth-child(3)"
  //       ).textContent;
  //   } else {
  //     userEmail = null;
  //     // userEmail = "sahil@kwiat.com";
  //   }
  //   // console.log("userEmail: ", userEmail);

  //   const appbaseRef = Appbase({
  //     app: AppbaseEmployeeApp,
  //     url: AppbaseAppUrl,
  //     credentials: AppbaseCredentials,
  //   });
  //   if (userEmail) {
  //     await appbaseRef
  //       .search({
  //         body: {
  //           query: {
  //             match_phrase: {
  //               Email: {
  //                 query: userEmail,
  //               },
  //             },
  //           },
  //           size: 10000,
  //           // stored_fields: ["Email"], // for getting only ids
  //           _source: [
  //             "FirstName",
  //             "LastName",
  //             "Email",
  //             "BasketEmail",
  //             "EmployeeLogin",
  //             "Title",
  //             "Phone1",
  //             "AddressLine1",
  //             "AddressLine2",
  //             "City",
  //             "State",
  //             "PostalCode",
  //             "BasketBccEmail",
  //             "BasketCcEmail",
  //             "CRMBccEmail",
  //           ],
  //         },
  //       })
  //       .then((response) => {
  //         console.log("User Email Found Successfully");
  //         // console.log("response : ", response.hits.hits[0]._source);
  //         // this.setState({ user: response.hits.hits[0]._source });
  //         this.props.setBasketFormInput({
  //           user: response.hits.hits[0]._source,
  //         });
  //         // console.log("Basket form data: ", this.props.basketInputObj);
  //         // result = { statusCode: 200, data: response };
  //       })
  //       .catch((error) => {
  //         console.log("User Email Error");

  //         // console.log("error : ", error);
  //         // return;
  //         // result = { statusCode: 500, data: error };
  //       });
  //     // let payload = {
  //     //   data: {
  //     //     defaults: {
  //     //       baseURL: basketBaseUrl,
  //     //       token: this.props.tokenState.token,
  //     //     },
  //     //     inputs: {
  //     //       EmployeeLogin: {
  //     //         value: this.props.basketInputObj.user.EmployeeLogin,
  //     //       },
  //     //     },
  //     //   },
  //     // };
  //     // // console.log("payload: ", payload);
  //     // await axios
  //     //   .post(appbaseBasketUrl + "user-signature", payload, {
  //     //     headers: {
  //     //       "x-api-key": basketApikey,
  //     //     },
  //     //   })
  //     //   .then((res) => {
  //     //     // console.log("resp : ", res);
  //     //     if (
  //     //       res.data.statusCode === 200 &&
  //     //       res.data.body.KWMailSignature.value
  //     //     ) {
  //     //       console.log("User signature found.");
  //     //       // this.setState((prevState) => ({
  //     //       //   user: {
  //     //       //     ...prevState.user,
  //     //       //     Signature: res.data.body.KWMailSignature.value,
  //     //       //   },
  //     //       // }));
  //     //       this.props.setBasketFormInput({
  //     //         user: {
  //     //           ...this.props.basketInputObj.user,
  //     //           Signature: res.data.body.KWMailSignature.value,
  //     //         },
  //     //       });
  //     //     } else {
  //     //       console.log("response is empty");
  //     //     }
  //     //     // console.log("Basket form data: ", this.props.basketInputObj);
  //     //     // return JSON.parse(res.data.body);
  //     //     // return res;
  //     //   })
  //     //   .catch((err) => {
  //     //     // console.log("error : ", err);
  //     //     console.log("User Signature error.", err);
  //     //     // return;
  //     //   });
  //   } else {
  //     console.log("No user");
  //   }
  //   // if (window.parent.document.getElementById("btnUserMenu")) {
  //   //   let userDetails = window.parent.document
  //   //     .getElementById("userMenu")
  //   //     .querySelector("li.menu-form > div.inline > span:nth-child(3)").textContent;
  //   //   console.log("userDetails: ", userDetails);
  //   // }
  // }

  // async getContactEmail() {
  //   var custobj = this.props.basketInputObj.customer;
  //   var contobj = this.props.basketInputObj.contact;
  //   // console.log("Customer details: ", custobj, "\nContact details: ", contobj);
  //   if (custobj && contobj) {
  //     var token = this.props.tokenState.token;
  //     var custId;
  //     if (custobj.CustomerId) {
  //       custId = custobj.CustomerId;
  //     } else {
  //       custId = null;
  //     }
  //     var payload = {
  //       data: {
  //         defaults: {
  //           baseURL: basketBaseUrl,
  //           token: token,
  //         },
  //         inputs: {
  //           CustomerID: { value: custId },
  //           Contact: { value: contobj.DisplayName },
  //         },
  //       },
  //     };
  //     var response = await axios
  //       .post(ApiBaseUrl + "contact", payload, {
  //         headers: {
  //           "x-api-key": ApiKey,
  //         },
  //       })
  //       .then((res) => {
  //         // console.log("resp : ", res);
  //         // return JSON.parse(res.data.body);
  //         return res;
  //       })
  //       .catch((err) => {
  //         // console.log("error : ", err);
  //         console.log("Contact error.");
  //         this.props.toggleLoader({
  //           isLoading: false,
  //         });
  //         return;
  //       });
  //     if (
  //       response.data &&
  //       response.data.statusCode === 200 &&
  //       JSON.parse(JSON.parse(response.data.body).Contacts.value)
  //     ) {
  //       console.log("Contact found");
  //       // console.log(
  //       //   "Contact Response: ",
  //       //   JSON.parse(JSON.parse(response.data.body).Contacts.value)
  //       // );
  //       let contDetails = JSON.parse(
  //         JSON.parse(response.data.body).Contacts.value
  //       );
  //       for (let i = 0; i < contDetails.length; i++) {
  //         if (contDetails[i].ContactId === contobj.ContactId) {
  //           this.props.setBasketFormInput({ contact: contDetails[i] });
  //         }
  //       }
  //       // console.log("Basket form data: ", this.props.basketInputObj);
  //     } else if (JSON.parse(response.data.body).errorCode === 401) {
  //       // window.alert("Conatct error.");
  //       console.log("Contact Error");
  //       // console.log("contact error: ", JSON.parse(response.data.body));
  //       return;
  //     }
  //   } else {
  //     console.log("Customer or contact empty.");
  //   }
  // }
  async handleEmail(res) {
    console.log("Inside ");
    // if (
    //   this.props.basketInputObj.orderNbr &&
    //   this.props.basketInputObj.orderNbr !== "New"
    // ) {
    this.props.toggleLoader({
      isLoading: true
    });
    // await this.getUserEmail();
    // await this.getContactEmail();
    // this.handleBasketChange("Email");
    this.props.toggleLoader({
      isLoading: false
    });
    this.showChooseLayout("Email", res);

    // } else {
    //   window.alert("Please select a basket first");
    // }
  }

  handleCurrentRes(res) {
    this.setState({ currentRes: [res] });
  }

  handleSetCover(cover) {
    // console.log("layout: ", layout);
    this.setState({ coverType: cover });
  }

  handleSetLayout(layout) {
    // console.log("layout: ", layout);
    this.setState({ layoutType: layout });
  }

  showChooseLayout(value, res) {
    this.setState({
      showChooseLayout: true,
      goto: value
      // currentRes: [res],
    });
    this.handleCurrentRes(res);
  }
  hideChooseLayout() {
    this.setState({
      showChooseLayout: false
    });
  }

  showPDFModal() {
    this.setState({
      showPDFModal: true
    });
  }
  hidePDFModal() {
    this.setState({
      showPDFModal: false
    });
  }

  showPreviewModal() {
    this.setState({
      showPreviewModal: true
    });
  }
  hidePreviewModal() {
    this.setState({
      showPreviewModal: false
    });
  }

  showSelectedResults(signal) {
    this.setState({ showSelectedResultsOnEnter: signal });
  }

  clearFilters() {
    this.setState({
      showFilteredData: false,
      serialSearchSelectedSignal: false,
      showSelectedResultsOnEnter: false,
      keywordSearchSelectedSignal: false,
      rfidSearchSelectedSignal: false
    });
  }
  rfidSearchChanged(signal) {
    // console.log("Inside rfid Search");
    this.setState({
      rfidSearchSignal: signal
    });
  }

  rfidSearchSelected(signal) {
    // console.log("Inside serial search selected");
    this.setState({
      rfidSearchSelectedSignal: signal
    });
  }

  keywordSearchChanged(signal) {
    // console.log("Inside keywordSearchChanged");
    this.setState({
      keywordSearchSignal: signal
    });
  }

  keywordSearchSelected(signal) {
    // console.log("Inside keywordSearchSelected");
    this.setState({
      keywordSearchSelectedSignal: signal
    });
  }

  onSearch(value) {
    let { countValue } = this.state;
    if (value === "loadMore") {
      this.setState({
        showFilteredData: true,
        sizeLimit: countValue * 15,
        countValue: countValue + 1,
        fromLoadMore: true,
        loadMoreClicked: true
      });
    } else {
      this.setState({
        showFilteredData: true,
        sizeLimit: 1 * 15,
        countValue: 1 + 1
      });
    }
    setTimeout(() => {
      this.setState({
        loadMoreClicked: false
      });
    }, 1200);
  }
  handleAllOptions() {
    let { mobileClassToggle } = this.state;
    this.setState({
      mobileClassToggle: !mobileClassToggle
    });
  }
  onCheckSelect(value) {
    this.setState({
      checked: value
    });
  }
  onSelect(value, title, sort, active) {
    // console.log(active);
    this.setState({
      selected: value,
      title: title,
      sort: sort,
      active: active
    });
  }

  handleMouseEnter(e) {
    e.preventDefault();
    let serialNode = e.currentTarget.querySelector(".product-index_container");
    let shareNode = e.currentTarget.querySelector(".es__product__hovered");
    serialNode.classList.add(
      "product-index_container",
      "pos-relative",
      "serial-hovered"
    );
    shareNode.classList.add(
      "pos-relative",
      "es__bordered-box",
      "share-hovered"
    );
  }
  handleMouseLeave(e) {
    let serialNode = e.currentTarget.querySelector(".product-index_container");
    let shareNode = e.currentTarget.querySelector(".es__product__hovered");
    serialNode.classList.remove("serial-hovered");
    shareNode.classList.remove("share-hovered");
  }
  handleMail(msgBody) {
    window.location = "mailto:?&body=" + msgBody;
  }
  handleWhatsApp(msgBody) {
    window.open("https://api.whatsapp.com/send?text=" + msgBody, "_blank");
  }
  handleTextMsg(msgBody) {
    window.location = "sms:321-555-1111?&body=" + msgBody;
  }
  downloadResults(dynamicQuery) {
    const appbase = Appbase({
      url: this.props.url,
      app: this.props.app,
      credentials: this.props.credential
    });
    var limit = 1000;
    var offset = 0; //The offset from the first result you want to fetch.
    var doiExists = true;
    var totalRecords = 0;
    let data = [];
    async.whilst(
      function() {
        return doiExists;
      },
      callback => {
        appbase
          .search({
            from: offset,
            size: limit,
            body: [dynamicQuery]
          })
          .then(response => {
            offset = offset + limit;
            if (response.hits.hits.length < limit) {
              doiExists = false;
            }
            totalRecords = totalRecords + response.hits.hits.length;
            let resData = response.hits.hits;

            for (var i = resData.length - 1; i >= 0; i--) {
              var onlySource = resData[i]["_source"];
              delete resData[i]["_source"];
              var newObject = merge({}, resData[i], onlySource);
              if (this.state.access !== "advanced") {
                for (var j = gemstoneDataExport.length - 1; j >= 0; j--) {
                  delete newObject[gemstoneDataExport[j]];
                }
              }
              data.push(newObject);
            }
            callback();
          })
          .catch(error => {
            callback(error);
          });
      },
      err => {
        if (err) {
          console.log(err);
        }
        this.setState(
          {
            csvData: data
          },
          () => {
            this.csvLink.current.link.click();
          }
        );
      }
    );
  }
  handleQueryChange(prevQuery, nextQuery) {
    let { sizeLimit, countValue, fromLoadMore, loadMoreClicked } = this.state;
    this.setState({
      countValue: fromLoadMore ? countValue : 2
    });
    if (loadMoreClicked) {
      this.setState({
        showCustomLoader: false
      });
    } else if (prevQuery) {
      this.setState({
        showCustomLoader: true,
        sizeLimit: fromLoadMore ? sizeLimit : 15
      });
    }
    setTimeout(() => {
      this.setState({
        showCustomLoader: false,
        fromLoadMore: false,
        nextQuery
      });
    }, 1200);
  }
  serialSearchChanged(signal) {
    this.setState({
      serialSearchSignal: signal
    });
  }
  handleSoldSignal(signal) {
    this.setState({
      soldSignal: signal
    });
  }
  serialSearchSelected(signal) {
    // Value coming from child component
    this.setState({
      serialSearchSelectedSignal: signal
    });
  }
  mountedSearchChanged(mountval) {
    this.setState({
      mountedSearchSignal: mountval
    });
  }
  nLeftPad(n) {
    return n > 9 ? "" + n : "0" + n;
  }
  showStockPopUpModal(e, item) {
    let { access } = this.state;
    if (access !== "advanced") {
      return;
    }
    this.setState({
      result: item,
      showStockModal: true
    });
  }
  showSoldModal(e, item) {
    // let { access } = this.state;
    // if (access !== "advanced") {
    //   return;
    // }
    this.setState({
      soldModal: true,
      result: item
    });
  }
  showZoomImage(e, res) {
    // console.log("Clicked Zoom", res);
    this.setState({
      showImageModal: true,
      zoomImage: res.LargeImageFileID
    });
  }
  showMobileProductModal(item) {
    this.setState({
      result: item,
      showMobileProductModal: true
    });
  }
  hideMobileProductModal() {
    this.setState({
      showMobileProductModal: false
    });
  }
  hideSoldModal() {
    this.setState({
      soldModal: false
    });
  }
  hideStockPopUpModal() {
    this.setState({
      showStockModal: false
    });
  }
  hideImageModal() {
    this.setState({
      showImageModal: false
    });
  }

  createStatusLink(linktype) {
    let link;
    if (linktype === "SOLD") {
      link = "/pages/ar/ar301000.aspx?PopupPanel=On&DocType=INV&RefNbr=";
    } else if (linktype === "MEMO") {
      link = "/pages/kw/KW801001.aspx?PopupPanel=On&MemoNbr=";
    } else if (linktype === "RTV") {
      link = "/pages/po/po302000.aspx?PopupPanel=On&ReceiptType=RN&ReceiptNbr=";
    } else if (linktype === "ON SALES ORDER") {
      link = "/pages/so/so301000.aspx?PopupPanel=On&OrderNbr=";
    } else if (linktype === "ON SHIPMENT") {
      link = "/pages/so/so302000.aspx?PopupPanel=On&ShipmentNbr=";
    } else if (linktype === "In Production") {
      link = "/pages/am/AM300000.aspx?PopupPanel=On&DocType=M&BatNbr=";
    } else if (linktype === "Adjusted Out") {
      link = "/pages/in/IN303000.aspx?PopupPanel=On&DocType=A&RefNbr=";
    }
    return link;
  }
  getProduct(results) {
    // this.setState((prevState) => ({
    //   ...(JSON.stringify(prevState.results) !== JSON.stringify(results)
    //     ? { results: results }
    //     : {}),
    // }));
    let img;
    let str = "";
    let { checked } = this.state;

    function isValueEmpty(res) {
      let result = "";
      if (!isEmpty(res)) {
        result = res;
      }
      return result;
    }
    function isMultipleValueEmpty(res, expr) {
      let result = "";
      switch (expr) {
        case "WholesalePrice":
          if (checked === true) {
            result = res.WholesalePriceCode || "";
          } else {
            result =
              (res.WholesalePrice &&
                currencyFormatter.format(`${res.WholesalePrice}`, {
                  code: "USD",
                  precision: 0
                })) ||
              "";
          }
          break;
        case "WholesalePerCarat":
          if (checked === true) {
            result = res.WholesalePerCaratCode || "";
          } else {
            result =
              (res.WholesalePerCarat &&
                currencyFormatter.format(`${res.WholesalePerCarat}`, {
                  code: "USD",
                  precision: 0
                })) ||
              "";
          }
          break;

        case "RetailPrice":
          if (!isEmpty(res)) {
            result = currencyFormatter.format(`${res}`, {
              code: "USD",
              precision: 0
            });
          }
          break;
        default:
          return result;
      }
      return result;
    }

    function showimage(image) {
      if (image && image != null) {
        img = image;
        str = img.split("=");
        if (str[1] && str[1].trim() === "") {
          img = "";
          str = "";
        }
      } else {
        img = "";
        str = "";
      }
      return img;
    }
    function largeImage(image) {
      let largeImg,
        largeStr = "";
      if (image && image != null) {
        let searchLargeImg;
        searchLargeImg = image;
        largeStr = searchLargeImg.split("\\");
        searchLargeImg = largeStr[largeStr.length - 1];
        largeImg =
          "https://cdn.kwiat.com/source-images/large/" + searchLargeImg;
      } else {
        largeImg = "";
        largeStr = "";
      }
      return largeImg;
    }
    // console.log("gemstone", results);
    return (
      <div className="jewelry-products">
        <button
          className="search_result_add_basket"
          onClick={event => {
            event.preventDefault();
            this.handleAddToBasket(results);
            return false;
            // debugger;
            // // this.props.toggleLoader({
            // //   isLoading: true,
            // // });

            // results.map((res, i) => {
            //   this.props.addToCart({
            //     product: res,
            //     productType: "J",
            //   });
            // });

            // // this.props.toggleLoader({
            // //   isLoading: false,
            // // });
            // debugger;
          }}
        >
          Add To Basket
        </button>

        <GemstoneAppResults
          results={results}
          isEmpty={isEmpty}
          isValueEmpty={isValueEmpty}
          // replaceDiscription={replaceDiscription}
          isMultipleValueEmpty={isMultipleValueEmpty}
          showimage={showimage}
          // diaIcon={diaIcon}
          largeImage={largeImage}
          handleMouseEnter={this.handleMouseEnter}
          handleMouseLeave={this.handleMouseLeave}
          nLeftPad={this.nLeftPad}
          addToCart={this.props.addToCart}
          setBasketFormInput={this.props.setBasketFormInput}
          toggleIframeModal={this.props.toggleIframeModal}
          handleSetCover={this.handleSetCover}
          handleIncludeGIA={this.handleIncludeGIA}
          handleEmail={this.handleEmail}
          showChooseLayout={this.showChooseLayout}
          showZoomImage={this.showZoomImage}
          showSoldModal={this.showSoldModal}
          handleShowFileIdNames={this.handleShowFileIdNames}
          showStockPopUpModal={this.showStockPopUpModal}
          // handleEditHoldNoteModal={this.handleEditHoldNoteModal}
          // handleHoldNotesModals={this.handleHoldNotesModals}
          // handleHoldNoteArchive={this.handleHoldNoteArchive}
          basketInputObj={this.props.basketInputObj}
          feedRestrictionsData={this.state.feedRestrictionsData}
          jewelryRestrictionData={this.state.jewelryRestrictionData}
          employeeData={this.state.employeeData}
          showMobileProductModal={this.showMobileProductModal}
          createStatusLink={this.createStatusLink}
          toggleLoader={this.props.toggleLoader}
        />
        <ShowFileModal
          show={this.state.showFileModal}
          hide={this.hideFileModal}
          fileData={this.state.fileData}
        />
        <PreviewEmailModal
          show={this.state.showPreviewModal}
          hide={this.hidePreviewModal}
          layoutType={this.state.layoutType}
          coverType={this.state.coverType}
          showChooseLayout={this.showChooseLayout}
          item={
            this.state.currentRes.length && this.state.currentRes[0]
              ? this.state.currentRes
              : []
          }
          handleCurrentRes={this.handleCurrentRes}
          // user={this.stat}
          filename={"Kwiat-Fred-Leighton"}
          includeGIA={this.state.includeGIA}
          coverSelected={this.state.coverSelected}
          // user={this.stat}
        />
        <PDFModal
          show={this.state.showPDFModal}
          hide={this.hidePDFModal}
          layoutType={this.state.layoutType}
          coverType={this.state.coverType}
          showChooseLayout={this.showChooseLayout}
          item={
            this.state.currentRes.length && this.state.currentRes[0]
              ? this.state.currentRes
              : []
          }
          handleCurrentRes={this.handleCurrentRes}
          filename={"KWFL"}
          includeGIA={this.state.includeGIA}
          coverSelected={this.state.coverSelected}
        />
        <ChooseLayoutModal
          show={this.state.showChooseLayout}
          hide={this.hideChooseLayout}
          setLayout={this.handleSetLayout}
          setCover={this.handleSetCover}
          showPreviewModal={this.showPreviewModal}
          showPDFModal={this.showPDFModal}
          goto={this.state.goto}
          showChooseLayout={this.showChooseLayout}
          coverType={this.state.coverType}
          includeGIA={this.state.includeGIA}
          handleIncludeGIA={this.handleIncludeGIA}
          item={
            this.state.currentRes.length && this.state.currentRes[0]
              ? this.state.currentRes
              : []
          }
          covers={this.state.covers}
          handleSelectedCover={this.handleSelectedCover}
          coverCategories={this.state.coverCategories}
          coverSelected={this.state.coverSelected}
          includeKTDShapes={this.state.includeKTDShapes}
          handleIncludeKTDShapes={this.handleIncludeKTDShapes}
        />
        {/* <HoldNotesArchievedModal
          show={this.state.showHoldNotesModal}
          hide={this.handleHoldNotesModals}
          holdNotesData={this.state.holdNotesData}
        />
        <HoldNoteCreateModal
          show={this.state.showHoldNoteCreateModal}
          hide={this.handleHoldNotesModals}
          // customers={this.state.customers}
          feedRestrictionsData={this.state.feedRestrictionsData}
          jewelryRestrictionData={this.state.jewelryRestrictionData}
          employeeData={this.state.employeeData}
          holdNotesData={this.state.holdNotesData}
          res={this.state.holdNoteItem}
          editHoldNote={this.state.showHoldNotesEditModal}
          handleItemHoldNoteUpdate={this.handleItemHoldNoteUpdate}
        /> */}
      </div>
    );
  }

  render() {
    let {
      showCustomLoader,
      countValue,
      sizeLimit,
      title,
      selected,
      sort,
      active,
      serialSearchSignal,
      serialSearchSelectedSignal,
      keywordSearchSignal,
      keywordSearchSelectedSignal,
      rfidSearchSignal,
      rfidSearchSelectedSignal,
      mountedSearchSignal,
      showStockModal,
      showImageModal,
      soldModal,
      zoomImage,
      result,
      checked,
      access,
      showFilteredData,
      mobileClassToggle,
      showMobileProductModal,
      nextQuery,
      csvData,
      soldSignal,
      showSelectedResultsOnEnter
    } = this.state;

    let mobileClass,
      andQuery = [];
    mobileClassToggle
      ? (mobileClass = "mobile-view-all")
      : (mobileClass = "mobile-view-less");

    if (serialSearchSignal === "ACTIVE" && mountedSearchSignal === "ACTIVE") {
      andQuery = [
        "SerialSearch",
        "IncludeSold",
        "MountedNumberStock",
        "LooseOnly"
      ];
    } else if (serialSearchSignal === "ACTIVE") {
      // console.log("serialSearchSignal", serialSearchSignal);
      andQuery = ["SerialSearch"];
    } else if (mountedSearchSignal === "ACTIVE") {
      // console.log("mountedSearchSignal");
      andQuery = ["MountedNumberStock", "IncludeSold", "LooseOnly"];
    } else if (rfidSearchSignal === "ACTIVE") {
      // console.log("Inside rfidCustSearchSignal");
      andQuery = ["RFID_Search"];
    } else {
      andQuery = [
        "StyleNumber",
        "SearchKeyword",
        "Warehouse",
        "RetailPriceRange",
        "CaratWeight",
        "IncludeSold",
        "LooseOnly",
        "IncludeRTV",
        "ExcludeConsignmentItem",
        "ExcludeVirtual",
        "WholeSalePriceRange",
        "GemstoneShape",
        "CountryofOrigin",
        "MountedNumberStock",
        "GemstoneType",
        "MemoOut",
        "GemEnhancement",
        "In_ShowCase"
      ];
    }
    this.defaultQuery = function() {
      return {
        track_total_hits: true,
        query: {
          match: { ItemStatus: "Active" }
        }
      };
    };
    return (
      <ReactiveBase
        className="es__base-component"
        app={this.props.app}
        url={this.props.url}
        credentials={this.props.credential}
      >
        <div className="searchContainer">
          <Row>
            <Col sm={3} className="FiltersListCol Col-1">
              {this.props.activeTabKey === "style" ? (
                ""
              ) : (
                <SerialNumber
                  data={this.props.serialData}
                  serialSearchChanged={this.serialSearchChanged}
                  serialSearchSelected={this.serialSearchSelected}
                  serialUpdate={this.state.postMessageSerial}
                />
              )}

              <StyleNumber data={this.props.styleData} />
              <GemstoneShape />
              <div className={mobileClass}>
                <MountedNumberStock
                  data={this.props.mountedStock}
                  mountedSearchChanged={this.mountedSearchChanged}
                />
                {!(isMobile || isIPad13) ? (
                  <>
                    <ShowCode onCheck={this.onCheckSelect} />
                    <HandleWholesale />
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col sm={3} className={"FiltersListCol Col-2 " + mobileClass}>
              <CaratWeight showSelectedResults={this.showSelectedResults} />
              <GemstoneType />
              <CountryofOrigin />
              <GemEnhancement />
            </Col>
            <Col sm={3} className={"FiltersListCol Col-3 " + mobileClass}>
              <Warehouse />
              <MemoOut />
              <RfidSearch
                rfidSearchChanged={this.rfidSearchChanged}
                rfidSearchSelected={this.rfidSearchSelected}
              />
            </Col>
            <Col sm={3} className="FiltersListCol Col-4">
              <RetailPriceRange
                showSelectedResults={this.showSelectedResults}
              />
              <div className={mobileClass}>
                {this.props.basketInputObj.showWholesale && (
                  <WholesalePriceRange
                    showSelectedResults={this.showSelectedResults}
                  />
                )}
                <div className="keyword-search">
                  <Keyword
                    keywordSearchSelected={this.keywordSearchSelected}
                    keywordSearchChanged={this.keywordSearchChanged}
                    data={this.props.gemstoneKeywords}
                  />
                </div>
                <Col sm={6} className="toggle-filters-listCol Col-1">
                  <LooseOnly />
                  <IsSold handleSoldSignal={this.handleSoldSignal} />
                  <IsVirtual />
                </Col>
                <Col sm={6} className="toggle-filters-listCol Col-2">
                  <IsRtv />
                  {this.state.access === "advanced" && <IsConsignmentItem />}

                  <InShowCase />
                  {isMobile || isIPad13 ? (
                    <>
                      <ShowCode onCheck={this.onCheckSelect} />
                      <HandleWholesale />
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} id="btn-target" className="search-btn">
              <button
                className="btn search-btn__button"
                onClick={() => this.onSearch("onSearch")}
                type="button"
              >
                Search
              </button>
            </Col>
            <div
              className="mobile-view-option"
              onClick={() => this.handleAllOptions()}
            >
              {mobileClassToggle ? "View Less Options" : "View All Options"}
            </div>
          </Row>
        </div>
        <SearchCriteria
          callback={this.clearFilters}
          includeFilter={this.handleFilter}
        />
        <SortDropdown
          onSelect={this.onSelect}
          title={title}
          active={active}
          soldSignal={soldSignal}
        />
        <div className="clearfix" />
        {showCustomLoader && <ProductLoader />}
        {showFilteredData ||
        keywordSearchSelectedSignal ||
        serialSearchSelectedSignal ||
        rfidSearchSelectedSignal ||
        showSelectedResultsOnEnter ? (
          <ReactiveList
            className={`results ${showCustomLoader ? "visiblityHidden" : ""}`}
            componentId="results"
            dataField={selected}
            size={sizeLimit}
            sortBy={sort}
            loader={<ProductLoader />}
            react={{
              and: andQuery
            }}
            renderResultStats={({ numberOfResults, time }) => {
              var node = document.getElementsByClassName("load__more--button");
              if (sizeLimit >= numberOfResults) {
                if (node.length) node[0].style.display = "none";
              } else {
                if (node.length) node[0].style.display = "block";
              }
              if (isMobileOnly) {
                return numberOfResults + " results found in " + time + "ms";
              } else {
                return (
                  <button
                    className="csv-btn"
                    onClick={() => {
                      this.props.activeTabKey === "style"
                        ? this.downloadResults(nextQuery)
                        : this.downloadResults(nextQuery);
                    }}
                  >
                    {numberOfResults} results found in {time}ms
                  </button>
                );
              }
            }}
            render={
              ({ data }) =>
                // {
                // this.setState({ results: data });
                this.getProduct(data)
              // }
            }
            onQueryChange={(prevQuery, nextQuery) =>
              this.handleQueryChange(prevQuery, nextQuery)
            }
            defaultQuery={this.defaultQuery}
            // scrollTarget={isMobile || isIPad13 ? "btn-target" : ""}
          />
        ) : (
          <div className="banner text-center">
            <img
              src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Search-Background-Gemstone.png"
              alt="banner"
              className="img-responsive"
            />
          </div>
        )}
        {(isMobile || isIPad13) && countValue > 1 && (
          <div className="text-center load__more--button">
            <button
              className="btn btn-default"
              onClick={() => this.onSearch("loadMore")}
              type="button"
            >
              Load More
            </button>
          </div>
        )}
        <CSVLink
          className="csv-link"
          data={csvData}
          filename={"GemstoneData.csv"}
          ref={this.csvLink}
        />
        {showStockModal && (
          <StockModal
            show={showStockModal}
            callback={this.hideStockPopUpModal}
            result={result}
            checked={checked}
          />
        )}
        {soldModal && (
          <SoldModal
            show={soldModal}
            access={access}
            callback={this.hideSoldModal}
            result={result}
            checked={checked}
          />
        )}
        {showImageModal && zoomImage !== null && (
          <ImageModal
            show={showImageModal}
            callback={this.hideImageModal}
            image={zoomImage}
          />
        )}
        {showMobileProductModal && (
          <MobileProductDetailModal
            show={showMobileProductModal}
            callback={this.hideMobileProductModal}
            result={result}
            checked={checked}
          />
        )}
      </ReactiveBase>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleIframeModal,
      toggleLoader,
      addToCart,
      setToken,
      setBasketFormInput
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
